import React, {Component} from 'react';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import { Layout } from '../layout/Layout';

class Experiments extends Component {
    render() {
        return (
            <>
                <Layout>
                    <Header active="experiments"/>

                    <section className="py-5 container">
                        <div className="row justify-content-md-center">
                            <div className="col-md-10 mx-auto">
                                <h1 className="font-weight-light"><span role="img" aria-label="">🧪</span> Side Projects</h1>
                                <p className="jumbotron-text">
                                    I love to tinker in both physical and digital world. These are some of the projects that are outcome of that.
                                </p>
                            </div>
                        </div>
                    </section>

                    <div className="container">
                        <div className="row justify-content-md-center">
                            <div className="project-content col col-md-10">
                                <h2 className='mt-0'>Pixees: Kickstarter for handmade acrylic animal magnets</h2>
                                <div class="videoWrapper">
                                    <center>
                                        <iframe width="640" height="360" src="https://player.vimeo.com/video/460830450" frameborder="0" title="Pixees Making" allowfullscreen></iframe>
                                    </center>
                                </div>
                                <p className="mt-2">Both me and my wife love making, hacking and DIY. So, for our firt anniversay, we decided to run a Make100 campaign on <a href="https://www.kickstarter.com/projects/mehulagr/make-100-handmade-animal-magnets-thepixees?ref=user_menu" target="_blank" rel="noreferrer">Kickstarter</a>. Not only did we have fun, the Kickstarter got <b>151% funded</b>. Pixees are cute little layered laser cut acrylic magnets assembled by hand. Pixees were also showcased in the <a href="https://makerfaire.com/bay-area/" target="_blank" rel="noreferrer">Maker Faire</a>, Bay Area in 2019.</p>                
                            </div>
                        </div>

                        <div className="row justify-content-md-center">
                            <div className="project-content col col-md-10">
                                <h2>Flaneur</h2>
                                <div class="videoWrapper">
                                    <center>
                                        <iframe width="640" height="360" src="https://player.vimeo.com/video/213139639" frameborder="0" title="Flaneur 3D audio testing" allowfullscreen></iframe>
                                    </center>
                                </div>
                                <p className="mt-2">In a group of 4, we explored technologies for visually impaired to help with the spatial awareness of the environments they may be in or the objects around them, instead of just focusing on point A to B navigation. We created a prototype of 3D audio technology. These are clips from pilot testing with users that have normal vision. We gave them a 3d audio and asked them to describe the scene and then guess where they are.</p>
                                <p>The project won the runner-up award in the <a href="https://luddy.indiana.edu/news/story.html?story=Sensory-overload-apparel-3-D-hearing-ideas-win-Cheng-Wu-Innovation-Challenge" target="_blank" rel="noreferrer">Cheng Wu Innovation Challenge in 2017</a>.</p>
                            </div>
                        </div>

                        <div className="row justify-content-md-center">
                            <div className="project-content col col-md-10">
                                <h2>IOT: Proximity based interactions</h2> 
                                <div class="videoWrapper">
                                    <center>
                                        <iframe src="https://player.vimeo.com/video/215088547" width="640" height="360" frameborder="0" title="Proxemic Interactions Demo" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                                    </center>
                                </div>
                                <p className="mt-2">For smart devices, interaction should happen where the object is. Context, here, is defined by proximity between user and object. The core idea is that users should be able to just walk up to the devices and use them without having to worry about different knobs and switches. For-example, imagine if you can just walk up to any printer and print by just a single tap. No more fiddling around to connect the printer with laptop, mobile, etc. This was the premise for “Proxemic connected micro-interactions between devices.” </p>                
                            </div>
                        </div>

                        <div className="row justify-content-md-center">
                            <div className="project-content col col-md-10">
                                <h2>Microsoft: Digital Inking explorations</h2>
                                <div class="videoWrapper">
                                    <center>
                                        <iframe width="640" height="360" src="https://www.youtube.com/embed/ASaXe4QybIA" frameborder="0" title="Microsoft Digital Inking Demo" allowfullscreen></iframe>
                                    </center>
                                </div>
                                <p className="mt-2">The existing inking and note taking apps for tablet like Evernote, OneNote etc. are powerful, but they neither harness all the capabilities of digital medium nor leverage the day-to day actions that people perform while taking notes using paper and pen. Although these apps add value by integrating common functions like cut, copy, paste, undo, redo, stroke color changes, etc. but they don't allow users to perform complex functions like calculations, visualize curves, search etc.</p>
                                <p>In a group of 4, We looked at the user group of college student who actively take notes during lecture and while organising event meetings. Our attempt was to abstract their behavior and design a seamless note-taking application that combines “natural” actions with powerful digital functions. The application was designed specifically for Microsoft Surface Tablet and proposed features leveraged tablet specifications including the stylus.</p>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </Layout>
            </>
        )
    }
}

export default Experiments